var toggle = function() {
  this.class = 'is-toggled';

  this.init = function() {
    var $t = document.querySelectorAll('[data-toggle]');
    
    for(var $i=0; $i<$t.length; $i++) {
      var $id = $t[$i].getAttribute('data-toggle'),
          $toggle = $t[$i],
          $panels = document.querySelectorAll('[data-toggle-id="' + $id + '"]');

      this.addListener($toggle, $panels);
    }
  }

  this.addListener = function($toggle, $panels) {
    $toggle.onclick = function() {
      this.setState($toggle, $panels);
      return false;
    }.bind(this);
  }

  this.setState = function($toggle, $panels) {
    if($toggle.classList.contains(this.class)) {
      $toggle.classList.remove(this.class);
    } else {
      $toggle.classList.add(this.class);
    }

    for(var $i=0; $i<$panels.length; $i++) {
      if($panels[$i].classList.contains(this.class)) {
        $panels[$i].classList.remove(this.class);
      } else {
        $panels[$i].classList.add(this.class);
      }
    }
  }
}

new toggle().init();