var notification = function() {
  this.init = function() {
    var $n = document.querySelectorAll('[data-notification]');

    for(var $i=0; $i<$n.length; $i++) {
      var $panel = $n[$i],
          $toggle = $panel.querySelector('[data-notification-close]');

      if($toggle) {
        this.addListener($toggle, $panel);
      }
    }
  }

  this.addListener = function($toggle, $panel) {
    $toggle.onclick = function() {
      this.removeNotification($panel);
      return false;
    }.bind(this);
  }

  this.removeNotification = function($panel) {
    var $parent = $panel.parentNode;

    if($parent.children.length==1) {
      $parent.remove();
    } else {
      $panel.remove();
    }
  }
}

new notification().init();